import { Pipe, PipeTransform } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { isValid } from 'date-fns';
import { format, parseDate } from '../shared/date.helper';

@Pipe({
  name: 'time',
  pure: true,
  standalone: true,
})
export class TimePipe implements PipeTransform {
  public transform(time: string, defaultValue: string = ''): string {
    if (isEmpty(time)) {
      return defaultValue;
    }

    const date = parseDate(time);
    if (isValid(date)) {
      return format(date, 'HH:mm');
    }

    return time.substring(0, 5);
  }
}
